// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultLayout_bYLsu{display:flex;flex-direction:column;min-height:100vh}.main_RaT5\\+{flex:1 1 auto;min-height:-webkit-fill-available;position:relative}.modal_l0SyG{bottom:0;left:0;position:fixed;right:0;top:0;z-index:1000}.insight_TVLeG{transition:bottom,.3s ease}@media(max-width:1279.98px){.insight_TVLeG._media_JOZCt{display:none}}@media(max-width:767.98px){.hasTopContent_cTvUM{transform:translateY(-100px)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"DefaultLayout": "DefaultLayout_bYLsu",
	"main": "main_RaT5+",
	"modal": "modal_l0SyG",
	"insight": "insight_TVLeG",
	"_media": "_media_JOZCt",
	"hasTopContent": "hasTopContent_cTvUM"
};
module.exports = ___CSS_LOADER_EXPORT___;
