
// Vuex
import { mapState } from 'vuex';

// Components
import TheHeader from 'portal/components/layout/TheHeader.vue';
import TheModal from 'portal/components/layout/TheModal.vue';
import TheToast from 'portal/components/layout/TheToast.vue';

export default {
    name: 'ProfileLayout',
    components: {
        TheToast,
        TheModal,
        TheHeader,
    },

    computed: {
        ...mapState({
            user: state => state.user?.user,
        }),
    },

    watch: {
        user(val) {
            if (!val) {
                this.$router.push('/');
            }
        },
    },
};
