
// vuex
import { mapActions } from 'vuex';
// Components
import TheModal from '~/components/layout/TheModal';

export default {
    name: 'EmptyLayout',

    components: {
        TheModal,
    },

    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: `${this.siteDomain}${this.$route.path}`,
                },
            ],

            meta: [
                {
                    hid: 'og:type',
                    name: 'og:type',
                    content: 'website',
                },
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: `${this.siteDomain}${this.$route.path}`,
                },
            ],
        };
    },

    mounted() {
        this.$nextTick(() => {
            const touchSupported = 'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0;

            this.setTouchSupport(touchSupported);
        });
    },

    methods: {
        ...mapActions({
            setTouchSupport: 'device/changeIsTouch',
        }),
    },
};
