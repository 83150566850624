
// Components
import VButton from 'portal/components/ui/button/VButton.vue';

export default {
    name: 'Error',

    components: {
        VButton,
    },

    props: {
        error: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        list() {
            return [
                {
                    code: 404,
                    title: 'Данной страницы <br /> не существует',
                    description: 'Возможно был введен некорректный адрес <br /> или страница была удалена',
                    image: '/images/errors/404.png',
                    label: 'Вернуться на главную',
                    handler: this.requestRoute,
                },

                {
                    code: 502,
                    title: 'Ошибка на <br /> стороне сервера',
                    description: 'Попробуйте перезагрузить страницу',
                    image: '/images/errors/502.png',
                    label: 'Перезагрузить',
                    handler: this.requestRoute,
                },
            ];
        },
    },

    methods: {
        requestRoute(code) {
            if (code === 404) {
                this.$router.push('/');
            }

            if (code === 502) {
                this.$router.go();
            }
        },
    },
};
