export default function({ route }) {
    if (process.client) {
        const regex = /^\/(job|rating)/;
        const shouldShowJivo = regex.test(route.path);

        const jivoElements = [
            document.getElementById('jivo-iframe-container'),
            document.querySelector('jdiv'),
        ];

        jivoElements.forEach(element => {
            if (element) {
                element.style.display = shouldShowJivo ? 'block' : 'none';
            }
        });
    }
}
