export default async function({ route, redirect, store }) {
    await store.dispatch('user/fetchUser');

    const state = store.state.user;
    const { path, params } = route;

    if (!state?.user && path.includes('/profile')) {
        return redirect('/');
    }

    const isCompany = state?.user?.is_company;
    const isExpert = state?.user?.is_expert;
    const isExpertVoted = state.user?.vote === 0;
    const isExpertGraded = state.user?.is_expert_voted;

    // редирект на страницу пользователя со страниц компании
    if (!isCompany && ['/profile/company', '/profile/contacts', '/profile/vacancies'].includes(path)) {
        return redirect('/profile/info');
    }

    // редирект на страницу компании со страниц пользователя
    if (isCompany && ['/profile/info', '/profile/resumes'].includes(route.path)) {
        return redirect('/profile/company');
    }

    // редирект на страницу соответствующего пользователя со страницы профиля
    if (['/profile', '/profile/'].includes(route.path)) {
        return redirect(isCompany ? '/profile/company' : '/profile/info');
    }

    // редиректы со страниц /profile/job... на страницы резюме/вакансии в зависимости от текущего пользователя
    if (['/profile/job', '/profile/job/'].includes(route.path)) {
        return redirect(isCompany ? '/profile/vacancies' : '/profile/resumes');
    }

    // редиректы со страниц /profile/expert... для не экспертов
    if ((!isExpert || isCompany) && path.includes('/profile/expert')) {
        return redirect('/profile/info');
    }

    if (isExpertVoted && path.includes('/profile/expert/voting')) {
        return redirect('/profile/expert/grading');
    }

    if (isExpertGraded && path.includes('/profile/expert/grading')) {
        return redirect('/profile/expert/results');
    }

    const patterns = [
        { regex: /^\/profile\/job\/vacancy\/(\d+)$/, companyRedirect: `/profile/vacancies/${params.id}`, nonCompanyRedirect: '/profile/resumes' },
        { regex: /^\/profile\/job\/resume\/(\d+)$/, companyRedirect: `/profile/resumes/${params.id}`, nonCompanyRedirect: '/profile/vacancies' },
    ];

    for (const pattern of patterns) {
        if (pattern.regex.test(path)) {
            return redirect(isCompany ? pattern.companyRedirect : pattern.nonCompanyRedirect);
        }
    }
}
