// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileLayout_OUre2{min-height:100vh}.ProfileLayout_OUre2,.main_BEMgh{display:flex;flex-direction:column}.main_BEMgh{flex:1 1 auto;min-height:-webkit-fill-available;position:relative}.modal_MVVfP{bottom:0;left:0;position:fixed;right:0;top:0;z-index:1000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"ProfileLayout": "ProfileLayout_OUre2",
	"main": "main_BEMgh",
	"modal": "modal_MVVfP"
};
module.exports = ___CSS_LOADER_EXPORT___;
